import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { CashRoutes } from "./CashRoutes";

const FeatureCash = {
  HomePage: React.lazy(() => import("pages/cash/views/HomePage")),
  ServiceBeleeCashPage: React.lazy(
    () => import("pages/cash/views/ServiceBeleeCash")
  ),
  ServiceBeleeCashDetailPage: React.lazy(
    () => import("pages/cash/views/ServiceBeleeCashDetailPage")
  ),
  DispatchingBeleeCashPage: React.lazy(
    () => import("pages/cash/views/DispatchingCashPage")
  ),
};

export const CashAppRoutes = () => (
  <Routes>
    <Route key={"routeToCashPage"} element={<RequireAuth />}>
      <Route
        path={CashRoutes.toHomeCashPage()}
        element={<FeatureCash.HomePage />}
      />
      <Route
        path={CashRoutes.toServiceBeleeCashPage()}
        element={<FeatureCash.ServiceBeleeCashPage />}
      />
      <Route
        path={CashRoutes.toServiceBeleeCashDetailPage()}
        element={<FeatureCash.ServiceBeleeCashDetailPage />}
      />
      <Route
        path={CashRoutes.toDispatchingBeleeCashPage()}
        element={<FeatureCash.DispatchingBeleeCashPage />}
      />
    </Route>
  </Routes>
);
