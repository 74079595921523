import React from "react";

import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { BackOfficeRoutes } from "./BackOfficeRoutes";

const FeatureBackOffice = {
  Dashboard: React.lazy(() => import("pages/Dashboard")),
};

export const BackOfficeAppRoutes = () => (
  <Routes>
    <Route key={"routeRegisterCompanyPage"} element={<RequireAuth />}>
      <Route
        path={BackOfficeRoutes.toDashboard()}
        element={<FeatureBackOffice.Dashboard />}
      />
    </Route>
  </Routes>
);
