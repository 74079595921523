import { User } from "interfaces";
import Cookies from "js-cookie";
import { REGEX } from "utils";
import { InvitationAPIResponse } from "./ApiInterfaces";
import { client } from "./client";

export const getAuthUserApi = () => {};
export const logUserByPass = () => {};

// export const refreshtokenAPI = () => {
//   let user: User = {
//     id: "",
//     role: "",
//     accessToken: "",
//     email: "",
//     uniqid: "",
//   };
//   return user;
// };

export const signIn = async (
  email: string,
  password: string
): Promise<User | string> => {
  let errorResponse = {};
  try {
    const response = await client.post("/auth/signin", {
      email: email,
      password: password,
      adminOnly: true,
    });

    let today = new Date();
    today.setHours(
      today.getHours() + Number(response.data.accessToken.tokenExpiration)
    );

    if (response.status === 200 && response.data) {
      Cookies.set("beleeBOJwt", response.data.accessToken, {
        expires: today,
        path: "/",
      });
      return response.data as User;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const inviterUser = async (
  id: string,
  nom: string,
  prenom: string,
  email: string,
  phone: string,
  role: string
): Promise<string | InvitationAPIResponse> => {
  let errorResponse = "";
  try {
    const response = await client.post("/users/" + id + "/invite", {
      email: email,
      lastname: nom,
      firstname: prenom,
      phone: phone,
      role: role,
    });

    if (response.status === 200 && response.data) {
      return response.data as InvitationAPIResponse;
    }
  } catch (error: any) {
    errorResponse = error.response.data;
  }
  return errorResponse as string;
};

export const inviteSuperviseurOrEditeurAPI = async (
  email: string,
  lastname: string,
  firstname: string,
  password: string,
  role: string
): Promise<string | InvitationAPIResponse> => {
  let errorResponse = "";
  let data = {
    email,
    lastname,
    firstname,
    password,
    role,
  };
  try {
    const response = await client.post(
      "/users/invite/superviseur/editeur",
      data
    );

    if (response.status === 200 && response.data) {
      return response.data as InvitationAPIResponse;
    }
  } catch (error: any) {
    errorResponse = error.response.data;
  }
  return errorResponse as string;
};

export const retrieveInvitedUser = async (id: string) => {
  let errorResponse = "";
  try {
    const response = await client.get("users/invitations/liste/" + id);
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error: any) {
    errorResponse = error.response.data;
  }
  return errorResponse as string;
};

export const confirmerInvitation = async (
  id: string,
  email: string,
  lastname: string,
  firstname: string,
  genre: string,
  password: string,
  phone: string
): Promise<User | string> => {
  let errorResponse = "";
  try {
    const response = await client.post("users/invitations/liste/" + id, {
      email: email,
      lastname: lastname,
      firstname: firstname,
      password: password,
      phone: phone,
      genre: genre,
    });
    if (response.status === 200 && response.data) {
      return response.data as User;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const askToResetPassword = async (email?: string, phone?: string) => {
  let err = "";
  let data: any = {};
  if (email && email.search(REGEX.PHONE_REGEXP) === -1) {
    data.email = email;
  } else if (email && email.search(REGEX.EMAIL_REGEXP) === -1) {
    data.phone = email;
  } else if (phone && phone.search(REGEX.EMAIL_REGEXP) === -1) {
    data.phone = phone;
  } else {
    data = {
      email: email,
      phone: phone,
    };
  }
  try {
    const response = await client.post(
      "/users/validation/send-code-validation",
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (e: any) {
    err = e.response.data.message;
  }
  return err as string;
};

export const verifyCode = async (code: string) => {
  let err = "";
  try {
    const response = await client.post(
      "/users/validation/verify-code-validation",
      { code: code }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const resetPassword = async (id: string, password: string) => {
  let err = "";
  try {
    const response = await client.post("/auth/reset-password/" + id, {
      password: password,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const resetPasswordSuperAdmin = async (
  token_save: string,
  password: string
) => {
  let err = "";
  try {
    const response = await client.post("/auth/super-admin/create-password", {
      password: password,
      token_save: token_save,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const retrieveValidation = async (id: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/users/validation/verify-code-validation/" + id
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
