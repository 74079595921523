import {
  GammeType,
  SupplementType,
  TypeMobilierType,
  TypeVehiculeType,
} from "interfaces";
import { VehiculeType } from "interfaces/VehiculeType";
import { GammeData, SupplementData } from "./ApiInterfaces";
import { client, clientFile } from "./client";

export const getAllVehiculeAPI = async (
  entrepriseId: string,
  filter?: string,
  keyWord?: string
): Promise<VehiculeType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voiture/liste?filter=" +
        filter +
        "&keyWord=" +
        keyWord +
        "&entrepriseId=" +
        entrepriseId
    );
    if (response.status === 200) {
      return response.data as VehiculeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getCountAllVehiculeByEtatAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voitures/count/all/belee");
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllVehiculeEntrepriseAPI = async (
  entrepriseId: string,
  filter?: string,
  keyWord?: string
): Promise<VehiculeType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voitures/listes?filter=" +
        filter +
        "&keyWord=" +
        keyWord +
        "&entrepriseId=" +
        entrepriseId
    );
    if (response.status === 200) {
      return response.data as VehiculeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getVehiculeDetails = async (
  vehiculeId: string
): Promise<VehiculeType | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voiture/liste/" + vehiculeId
    );
    if (response.status === 200) {
      return response.data as VehiculeType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getTypeVehiculeDetailsAPI = async (
  typeVehicule_id: string
): Promise<TypeVehiculeType | string> => {
  let err = "";
  try {
    const response = await client.get("/type/voitures/get/" + typeVehicule_id);
    if (response.status === 200) {
      return response.data as TypeVehiculeType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getTypeMobilierDetailsAPI = async (
  typeMobilier_id: string
): Promise<TypeMobilierType | string> => {
  let err = "";
  try {
    const response = await client.get("/type/mobiliers/get/" + typeMobilier_id);
    if (response.status === 200) {
      return response.data as TypeMobilierType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const validerVehiculeAPI = async (vehiculeId: string, choice: any) => {
  let err = "";
  try {
    const response = await client.post(
      "/entreprise/voiture/validation/" + vehiculeId,
      { gamme: choice }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setEtatVehiculeAPI = async (vehiculeId: string, etat: string) => {
  let err = "";
  let data = {
    vehiculeId,
    etat,
  };
  try {
    const response = await client.post("/entreprise/voiture/set-etat", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllGammeAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voiture/gamme/");
    if (response.status === 200) {
      return response.data as GammeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllTypeVehiculeAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/type/voitures/listes");
    if (response.status === 200) {
      return response.data as TypeVehiculeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllTypeMobilierAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/type/mobiliers/listes");
    if (response.status === 200) {
      return response.data as TypeMobilierType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createGammeAPI = async (data: GammeData) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/entreprise/voiture/gamme/create",
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setTypeVehiculeAPI = async (data: TypeVehiculeType) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }
  try {
    const response = await clientFile.post("/type/voitures/set", formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setTypeMobilierAPI = async (data: TypeMobilierType) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }
  try {
    const response = await clientFile.post("/type/mobiliers/set", formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const updateGammeAPI = async (data: GammeData) => {
  let err = "";

  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post("/gamme/update/", formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const updateGammeEtat = async (gammeId: string, etat: string) => {
  let err = "";
  try {
    const response = await client.post(
      "/entreprise/voiture/gamme/update/" + gammeId,
      { etat: etat }
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const updateGammeVehiculeAPI = async (
  vehiculeId: string,
  gammeId: string
) => {
  let err = "";
  try {
    const response = await client.post("/voiture/gamme/update/" + vehiculeId, {
      gamme: gammeId,
    });
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const deleteGammeApi = async function (data: string) {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voiture/gamme/supprimer/" + data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteTypeVehiculeAPI = async function (typeVehicule_id: string) {
  let err = "";
  try {
    const response = await client.post(
      "/type/voitures/delete/" + typeVehicule_id
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteTypeMobilierAPI = async function (typeMobilier_id: string) {
  let err = "";
  try {
    const response = await client.post(
      "/type/mobilier/delete/" + typeMobilier_id
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const createSupplement = async (data: SupplementData) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/entreprise/voiture/supplement/create",
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteSupplemetApi = async function (data: string) {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voiture/supplement/supprimer/" + data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllSupplementAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voiture/supplement");
    if (response.status === 200) {
      return response.data as SupplementType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getNoteMoyenneVehiculeAPI = async (vehiculeId: any) => {
  let err = "";

  try {
    const response = await client.get("/voitures/note/moyenne/" + vehiculeId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getAllCommentsOfVehiculeAPI = async (vehiculeId: any) => {
  let err = "";

  try {
    const response = await client.get(
      "/voitures/note/commentaire/" + vehiculeId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
