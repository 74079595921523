import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { UtilsRoutes } from "./UtilsRoutes";

const UtilsBanque = {
  QuickParamsPage: React.lazy(
    () => import("pages/utils/views/QuickParamsPage")
  ),
  CommandePage: React.lazy(
    () => import("pages/utils/views/DebugCommandePage")
  ),
  CommandeDetailPage: React.lazy(
    () => import("pages/utils/views/DebugCommandeDetailPage")
  ),
};
export const UtilsAppRoutes = () => (
  <Routes>
    <Route key={"routeBanque"} element={<RequireAuth />}>
      <Route
        path={UtilsRoutes.toQuickParams()}
        element={<UtilsBanque.QuickParamsPage />}
      />
      <Route
        path={UtilsRoutes.toDebugCommande()}
        element={<UtilsBanque.CommandePage />}
      />
      <Route
        path={UtilsRoutes.toDebugCommandeDetail()}
        element={<UtilsBanque.CommandeDetailPage />}
      />
    </Route>
  </Routes>
);
