export const ModeleRoutes = {
  toMenuModele: (): string => "/modele/",

  toListeDepartementModeleTaxi: (): string => "/modele/taxi/departement/liste",
  toListeDepartementModeleVtc: (): string => "/modele/vtc/departement/liste",




  toListeSousDepartementModeleTaxi: (): string => "/modele/taxi/sous-departement/liste",
  toListeSousDepartementModeleVtc: (): string => "/modele/vtc/sous-departement/liste",



  toModifierDepartement: (idDepartementContenu?: string): string =>
    idDepartementContenu
      ? "/modele/departement/modifier/" + idDepartementContenu
      : "/modele/departement/modifier/:idDepartementContenu",



  toCreateDepartement: (): string => "/modele/departement/create",



  toCreateContenuDepartementModeleTaxi: (): string =>
    "/modele/taxi/departement/create/contenu",
  toCreateContenuDepartementModeleVtc: (): string =>
    "/modele/vtc/departement/create/contenu",





  toCreateContenuSousDepartementModeleTaxi: (): string =>
    "/modele/taxi/sous-departement/create/contenu",
  toCreateContenuSousDepartementModeleVtc: (): string =>
    "/modele/vtc/sous-departement/create/contenu",



  toDetailContenuDepartementModeleTaxi: (idDepartementContenu?: string): string =>
    idDepartementContenu
      ? "/modele/taxi/departement/contenu/" + idDepartementContenu
      : "/modele/taxi/departement/contenu/:idDepartementContenu",
  toDetailContenuDepartementModeleVtc: (idDepartementContenu?: string): string =>
    idDepartementContenu
      ? "/modele/vtc/departement/contenu/" + idDepartementContenu
      : "/modele/vtc/departement/contenu/:idDepartementContenu",






  toDetailContenuSousDepartementModeleTaxi: (idSousDepartementContenu?: string): string =>
    idSousDepartementContenu
      ? "/modele/taxi/sous-departement/contenu/" + idSousDepartementContenu
      : "/modele/taxi/sous-departement/contenu/:idSousDepartementContenu",
  toDetailContenuSousDepartementModeleVtc: (idSousDepartementContenu?: string): string =>
    idSousDepartementContenu
      ? "/modele/vtc/sous-departement/contenu/" + idSousDepartementContenu
      : "/modele/vtc/sous-departement/contenu/:idSousDepartementContenu",
};
