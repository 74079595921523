import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { WhatsappRoutes } from "./WhatsappRoutes";

const FeatureWhattsapp = {
  WhattsappPage: React.lazy(() => import("pages/whatsapp/views/WhatsappPage")),
  MessageGroupePage: React.lazy(
    () => import("pages/whatsapp/views/MessageParGroupePage")
  ),
};

export const WhatsappAppRoutes = () => (
  <Routes>
    <Route key={"routeToWhattsapp"} element={<RequireAuth />}>
      <Route
        path={WhatsappRoutes.toWhattsapp()}
        element={<FeatureWhattsapp.WhattsappPage />}
      />
      <Route
        path={WhatsappRoutes.toMessageGroupe()}
        element={<FeatureWhattsapp.MessageGroupePage />}
      />
    </Route>
  </Routes>
);
