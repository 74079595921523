import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
export function getCookie(name: string): string {
  if (typeof Cookies.get === "function") {
    const beleeBOJwt = Cookies.get(name);
    return beleeBOJwt ?? "";
  }

  return "";
}
/**
 *
 * @returns
 */
export const isValidToken = (): boolean => {
  const beleeBOJwt = Cookies.get("jwt");

  if (beleeBOJwt === null || beleeBOJwt === undefined) return false;

  try {
    const decodedJwt: { exp: number } = jwtDecode(beleeBOJwt ?? "");
    // check if beleeBOJwt has expired (1h)
    return decodedJwt.exp > Date.now() / 1000;
  } catch (error) {
    return false;
  }
};
export function deleteCookie(name: string) {
  const date = new Date();

  // set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
  document.cookie =
    "name=" + name + "; expires=" + date.toUTCString() + "; path=/";
  // store the beleeBOJwt in the cookie shell
  Cookies.set(name, "", { expires: new Date(0), path: "/" });
}
