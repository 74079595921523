import axios from "axios";
import { deleteCookie, getCookie } from "helpers/auth-helpers";

const requestHandler = (request: any) => {
  request.headers["x-access-token"] = getCookie("beleeBOJwt");

  return request;
};

const responseHandler = (response: any) => {
  if (response.status === 401) {
    deleteCookie("beleeBOJwt");
    window.location.href = "/login";
  }

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": getCookie("beleeBOJwt"),
  },
});

export const clientFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": getCookie("beleeBOJwt"),
  },
});

client.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

client.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
