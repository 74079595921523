import { AuthAppRoutes } from "pages/auth/AuthAppRoutes";
import { BackOfficeAppRoutes } from "pages/BackOfficeAppRoutes";
import { BanqueAppRoutes } from "pages/banques/BanqueAppRoutes";
import { CommandeAppRoutes } from "pages/commandes/CommandeAppRoutes";
import { CompteAppRoutes } from "pages/compte/CompteAppRoutes";
import { TarifcationAppRoutes } from "pages/tarification/TarifcationAppRoutes";
import { UtilsAppRoutes } from "pages/utils/UtilsAppRoutes";
import { VehiculeAppRoutes } from "pages/vehicule/VehiculeAppRoutes";
import { ZoneAppRoutes } from "pages/zones/ZoneAppRoutes";
import { WhatsappAppRoutes } from "pages/whatsapp/WhatsappAppRoutes";
import { CleanAppRoutes } from "pages/clean/CleanAppRoutes";
import { ModificationAppRoutes } from "pages/modifications/ModificationAppRoutes";
import { CashAppRoutes } from "pages/cash/CashAppRoutes";
import { ModeleAppRoutes } from "pages/modeleTemplate/ModeleAppRoutes";
import { ModeleCleanAppRoutes } from "pages/modeleTemplateClean/ModeleCleanAppRoutes";

export const BeleeApplicationRoutes = () => (
  <div>
    <BackOfficeAppRoutes />
    <AuthAppRoutes />
    <CompteAppRoutes />
    <CashAppRoutes />
    <CleanAppRoutes />
    <VehiculeAppRoutes />
    <CommandeAppRoutes />
    <ZoneAppRoutes />
    <TarifcationAppRoutes />
    <BanqueAppRoutes />
    <UtilsAppRoutes />
    <ModeleAppRoutes />
    <WhatsappAppRoutes />
    <ModificationAppRoutes />
    <ModeleCleanAppRoutes />
  </div>
);
