import { User } from "interfaces";
import { client } from "./client";

export const getAllUsersAPI = async (
  filter?: string,
  keyWord?: string
): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/users?filter=" + filter + "&keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllUserWithServiceAndVehicule = async (
  filter?: string,
  keyWord?: string
): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/users/services/vehicules?filter=" + filter + "&keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserDetails = async (
  userId: string
): Promise<User | string> => {
  let err = "";
  try {
    const response = await client.get("/users/" + userId);
    if (response.status === 200) {
      return response.data as User;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getCountAllUserByEtatAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/users/count/all/belee");
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const validerUserAPI = async (userId: string) => {
  let err = "";
  try {
    const response = await client.post("/users/" + userId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getInvitedUsersAPI = async (
  filter?: string,
  keyWord?: string
): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/users/invitations/liste?filter=" + filter + "&keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const bloqueManuelementUserAPI = async (userId: any) => {
  let err = "";
  try {
    const response = await client.post("/user/blocageManuel", userId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const debloqueManuelementUserAPI = async (userId: any) => {
  let err = "";
  try {
    const response = await client.post("/user/deblocageManuel", userId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const blockUserMustPayAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/user/block/must/pay", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getNoteMoyenneUserAPI = async (userId: any) => {
  let err = "";

  try {
    const response = await client.get("/user/note/moyenne/" + userId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getAllCommentsOfUserAPI = async (userId: any) => {
  let err = "";

  try {
    const response = await client.get("/user/note/commentaire/" + userId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getAllSuperviseurEditeurAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/users/get/superviseurs/editeurs");
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllUserBeleeAPI = async (): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get("/users");
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const searchAllUserAPI = async (keyword: string): Promise<any> => {
  let err = "";
  try {
    const response = await client.get(
      "/get/all/users/banque?keyWord=" + keyword
    );
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserBankDetailAPI = async (userId: string): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/banque/get/user/detail/" + userId);
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
