import { ForfaitType } from "interfaces/ForfaitType";
import { GammeSettingType } from "interfaces/GammeSettingType";
import { ParametreTarificationType } from "interfaces/ParametreTarificationType";
import { SupplementSettingType } from "interfaces/SupplementSettingType";
import { ForfaitData, ParametreDeviseData } from "./ApiInterfaces";
import { client } from "./client";

export const GetParametreAPI = async (id_pays?: any): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      id_pays ? "/zones/pays/setting/" + id_pays : "/zones/pays/setting/"
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getForfaitAPI = async (
  forfait_id?: any
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      forfait_id
        ? "/zones/parametre/tarification/forfait/" + forfait_id
        : "/zones/parametre/tarification/forfait/"
    );
    if (response.status === 200 || response.status === 201) {
      return response.data as ForfaitType[];
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const supprimerForfaitAPI = async (forfait_id: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/pays/forfait/delete/" + forfait_id
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setParametreAPI = async (data: ParametreDeviseData) => {
  let err = "";
  let link = "";
  if (data.pays) {
    link = "/zones/pays/setting/" + data.pays;
  } else {
    link = "/zones/pays/setting/";
  }

  try {
    const response = await client.post(link, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setDispatchingAPI = async (data: any) => {
  let err = "";
  let link = "/dispatching/set";

  try {
    const response = await client.post(link, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response;
  }

  return err as string;
};

export const setDispatchingCashAPI = async (data: any) => {
  let err = "";
  let link = "/dispatching/cash/set";

  try {
    const response = await client.post(link, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response;
  }

  return err as string;
};

export const getParametreDispatchingAPI = async () => {
  let err = "";
  let link = "/dispatching/";

  try {
    const response = await client.get(link);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
  // }
};

export const getParametreDispatchingCashAPI = async () => {
  let err = "";
  let link = "/dispatching/cash";

  try {
    const response = await client.get(link);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
  // }
};

export const setParametreGammeAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/zones/pays/gamme/setting", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setParametreSupplementAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/zones/pays/supplement/setting", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getForfaitListes = async (): Promise<ForfaitType[] | string> => {
  let err = "";

  try {
    const response = await client.get("/zones/pays/forfait/liste");
    if (response.status === 200 || response.status === 201) {
      return response.data as ForfaitType[];
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const createForfaitAPI = async (data: ForfaitData) => {
  let err = "";

  try {
    const response = await client.post("/zones/pays/forfait/", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getGammeSettingAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/zones/pays/gamme/setting");
    if (response.status === 200) {
      return response.data as GammeSettingType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getSupplementSettingAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/zones/pays/supplement/setting");
    if (response.status === 200) {
      return response.data as SupplementSettingType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getDispatchingEntrepriseAPI = async (entrepriseId: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/get/dispatching-commandes/" + entrepriseId
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setDispatchingEntrepriseAPI = async (
  entreprise: string,
  disptachEntreprise: string
) => {
  let err = "";
  let data = {
    entreprise,
    disptachEntreprise,
  };
  try {
    const response = await client.post(
      "/entreprise/set/dispatching-commandes",
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
