import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { CommandeRoutes } from "./CommandeRoutes";

const FeatureCommande = {
  CommandePage: React.lazy(() => import("pages/commandes/views/CommandePage")),
  CommandeDetailPage: React.lazy(
    () => import("pages/commandes/views/CommandeDetailPage")
  ),
};
export const CommandeAppRoutes = () => (
  <Routes>
    <Route key={"routeCommande"} element={<RequireAuth />}>
      <Route
        path={CommandeRoutes.toCommande()}
        element={<FeatureCommande.CommandePage />}
      />
      <Route
        path={CommandeRoutes.toCommandeDetail()}
        element={<FeatureCommande.CommandeDetailPage />}
      />
    </Route>
  </Routes>
);
