export const AuthRoutes = {
    toLogin: (): string => "/login",
    toRegister: (): string => "/register",
    toVerify: (id?: string): string => (id ? "/verify/" + id : "/verify/:id"),
    toSuccess: (): string => "/success",
    toResetPassword: (): string => "/reset-password",
    toResetPasswordConfirm: (): string => "/reset-password/verify",
    // toNewPassword: (id?: string): string =>
    //   id ? "/reset-password/new/" + id : "/reset-password/new/:id",
    toNewPassword: (): string => "/reset-password/new",
    toNewPassSuccess: (): string => "/reset-password/success",
    toConfirmAccount: (id?: string): string =>
        id ? "/confirm/" + id : "/confirm/:id",
};
