import CarIcon from "components/Elements/icons/CarIcon";
import ListIcon from "components/Elements/icons/ListIcon";
import LogoutIcon from "components/Elements/icons/LogoutIcon";
import PaymentIcon from "components/Elements/icons/PaymentIcon";
import UserIcon from "components/Elements/icons/UserIcon";
import WhatsAppIcon from "components/Elements/icons/WhatsAppIcon";
import ModeleIcon from "components/Elements/icons/ModeleIcon";
import ZoneIcon from "components/Elements/icons/ZoneIcon";
import { MenuChildType, MenuType } from "interfaces";
import { BanqueRoutes } from "pages/banques/BanqueRoutes";
import { CashRoutes } from "pages/cash/CashRoutes";
import { CleanRoutes } from "pages/clean/CleanRoutes";
import { CommandeRoutes } from "pages/commandes/CommandeRoutes";
import { CompteRoutes } from "pages/compte/CompteRoutes";
import { ModeleRoutes } from "pages/modeleTemplate/ModeleRoutes";
import { ModeleCleanRoutes } from "pages/modeleTemplateClean/ModeleCleanRoutes";
import { ModificationRoutes } from "pages/modifications/ModificationRoutes";
import { TarifcationRoutes } from "pages/tarification/TarifcationRoutes";
import { VehiculeRoutes } from "pages/vehicule/VehiculeRoutes";
import { WhatsappRoutes } from "pages/whatsapp/WhatsappRoutes";
import { ZoneRoutes } from "pages/zones/ZoneRoutes";
import { CreditCard, Edit, Search, Truck, User, Users } from "react-feather";

export const userMenuChild: MenuChildType[] = [
  {
    id: 1,
    text: "Utilisateur",
    link: CompteRoutes.toComptePage(),
  },
  {
    id: 2,
    text: "Entreprise",
    link: CompteRoutes.toEntreprisePage(),
  },
  {
    id: 3,
    text: "Services",
    link: CompteRoutes.toServicePage(),
  },
];

export const sousServiceBeleeCleanMenuChild: MenuChildType[] = [
  {
    id: 110,
    text: "Lavage auto à domicile",
    link: TarifcationRoutes.toTarificationClean("lavage_auto_a_dom"),
  },
  {
    id: 111,
    text: "Nettoyage de Matelas-Canapés-Fauteuils",
    link: TarifcationRoutes.toTarificationClean("nettoyage"),
  },
  {
    id: 112,
    text: "Ménage à domicile",
    link: "",
  },
  {
    id: 113,
    text: "Nettoyage industriel",
    link: "",
  },
];

export const menuSuperviseur: MenuType[] = [
  {
    id: 1,
    text: "Comptes",
    icon: <UserIcon />,
    link: CompteRoutes.toComptePage(),
  },
  {
    id: 2,
    text: "Commandes",
    icon: <ListIcon />,
    link: CommandeRoutes.toCommande(),
  },
];

export const menuEditeur: MenuType[] = [
  {
    id: 1,
    text: "Modèle",
    icon: <ModeleIcon />,
    link: ModeleRoutes.toMenuModele(),
  },
];

export const menus: MenuType[] = [
  { id: 2, text: "Zones", icon: <ZoneIcon />, link: ZoneRoutes.toZonePage() },
  {
    id: 1,
    text: "Véhicules",
    icon: <CarIcon />,
    link: VehiculeRoutes.toVehicule(),
  },
  {
    id: 6,
    text: "Tarification",
    icon: <CreditCard className="text-gray-300" size={22} />,
    link: ZoneRoutes.toParametrePaysPage(),
  },
  {
    id: 7,
    text: "Dispatching",
    icon: <Truck className="text-gray-300" size={22} />,
    link: ZoneRoutes.toParametreRegionPage(),
  },
  {
    id: 65,
    text: "Superviseurs/Editeurs",
    icon: <Users />,
    link: CompteRoutes.toSuperviseurComptePage(),
  },
  {
    id: 3,
    text: "Compte",
    icon: <UserIcon />,
    link: CompteRoutes.toComptePage(),
    // child: userMenuChild,
  },
  {
    id: 4,
    text: "Commandes",
    icon: <ListIcon />,
    link: CommandeRoutes.toCommande(),
  },
  {
    id: 5,
    text: "Banque",
    icon: <PaymentIcon />,
    link: BanqueRoutes.toBanque(),
  },
  {
    id: 35,
    text: "Modèle",
    icon: <ModeleIcon />,
    link: ModeleRoutes.toMenuModele(),
  },
  {
    id: 34,
    text: "Whattsapp",
    icon: <WhatsAppIcon />,
    link: WhatsappRoutes.toWhattsapp(),
  },
  {
    id: 71,
    text: "Modifcations",
    icon: <Edit />,
    link: ModificationRoutes.toListeModificationPage(),
  },
];

export const menusBeleeClean: MenuType[] = [
  { id: 2, text: "Zones", icon: <ZoneIcon />, link: "" },
  {
    id: 1,
    text: "Véhicules / Mobiliers",
    icon: <CarIcon />,
    link: CleanRoutes.toTypeVehiculeMobilierPage(),
  },
  {
    id: 101,
    text: "Frais et commissions",
    icon: <CreditCard className="text-gray-300" size={22} />,
    link: TarifcationRoutes.toFraisCommissionLavageAutoADomicile(),
  },
  {
    id: 102,
    text: "Sous-services",
    icon: <ListIcon />,
    link: "",
    child: sousServiceBeleeCleanMenuChild,
  },
  // {
  //   id: 6,
  //   text: "Tarification",
  //   icon: <CreditCard className="text-gray-300" size={22} />,
  //   link: TarifcationRoutes.toTarificationClean(),
  // },
  {
    id: 7,
    text: "Dispatching",
    icon: <Truck className="text-gray-300" size={22} />,
    link: "",
  },
  {
    id: 3,
    text: "Compte",
    icon: <UserIcon />,
    link: "",
    //child: userMenuChild,
  },
  {
    id: 4,
    text: "Commandes",
    icon: <ListIcon />,
    link: "",
  },
  {
    id: 5,
    text: "Banque",
    icon: <PaymentIcon />,
    link: "",
  },
  {
    id: 35,
    text: "Modèle",
    icon: <ModeleIcon />,
    link: ModeleCleanRoutes.toMenuModele(),
  },
  {
    id: 34,
    text: "Whattsapp",
    icon: <WhatsAppIcon />,
    link: "",
  },
];

export const menusBeleeCash: MenuType[] = [
  { id: 2, text: "Zones", icon: <ZoneIcon />, link: "" },
  {
    id: 1,
    text: "Véhicules",
    icon: <CarIcon />,
    link: "",
  },
  {
    id: 6,
    text: "Tarification",
    icon: <CreditCard className="text-gray-300" size={22} />,
    link: ZoneRoutes.toParametreBeleeCash(),
  },
  {
    id: 7,
    text: "Dispatching",
    icon: <Truck className="text-gray-300" size={22} />,
    link: CashRoutes.toDispatchingBeleeCashPage(),
    // child: userMenuChild,
  },
  {
    id: 65,
    text: "Superviseurs",
    icon: <Users />,
    link: "",
  },
  {
    id: 3,
    text: "Compte",
    icon: <UserIcon />,
    link: "",
  },
  {
    id: 4,
    text: "Commandes",
    icon: <ListIcon />,
    link: "",
  },
  {
    id: 5,
    text: "Banque",
    icon: <PaymentIcon />,
    link: "",
  },
  {
    id: 34,
    text: "Whattsapp",
    icon: <WhatsAppIcon />,
    link: "",
  },
  {
    id: 71,
    text: "Modifcations",
    icon: <Edit />,
    link: "",
  },
];

export const searchMenu: MenuType[] = [
  {
    id: 9,
    text: "Search",
    icon: <Search className="text-gray-300" />,
    link: "",
  },
];

export const firstGroup: MenuType[] = [
  { id: 2, text: "Zones", icon: <ZoneIcon />, link: ZoneRoutes.toZonePage() },
  {
    id: 1,
    text: "Véhicules",
    icon: <CarIcon />,
    link: VehiculeRoutes.toVehicule(),
  },
  {
    id: 6,
    text: "Tarification",
    icon: <CreditCard className="text-gray-300" size={22} />,
    link: ZoneRoutes.toParametrePaysPage(),
  },
  {
    id: 7,
    text: "Dispatching",
    icon: <Truck className="text-gray-300" size={22} />,
    link: ZoneRoutes.toParametreRegionChoixPaysPage(),
  },
];

export const secondGroup: MenuType[] = [
  {
    id: 3,
    text: "Compte",
    icon: <User className="text-gray-300" />,
    link: CompteRoutes.toComptePage(),
  },
  {
    id: 4,
    text: "Commandes",
    icon: <ListIcon />,
    link: CommandeRoutes.toCommande(),
  },
  {
    id: 5,
    text: "Banque",
    icon: <PaymentIcon />,
    link: BanqueRoutes.toBanque(),
  },
];

export const logoutMenu: MenuType[] = [
  {
    id: 10,
    text: "Déconnexion",
    icon: <LogoutIcon />,
    link: "",
  },
];
