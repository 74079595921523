import { useAuth } from "hooks/useAuth";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";

export const PrivateRoute = ({ children, ...rest }: any) => {
  let auth = useAuth();
  if (auth.isLoading) return <></>;

  return (
    <Route
      {...rest}
      render={({ location }: any) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: "/login",
            }}
            state={{ from: location }}
          />
        )
      }
    />
  );
};

export const PublicRoute = ({ children, ...rest }: any) => {
  let auth = useAuth();
  if (auth.isLoading) return <></>;
  return <Route {...rest} render={({ location }: any) => children} />;
};

export const RequireAuth = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  if (isLoading) return null;

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export const NotRequireAuth = () => {
  return <Outlet />;
};
