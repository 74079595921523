export const TarifcationRoutes = {
  toForfait: (): string => "/forfait",
  toForfaitDetail: (id?: any): string =>
    id ? "/forfait/detail/" + id : "/forfait/detail/:id",
  toTarificationClean: (sousService?: string): string =>
    sousService
      ? "/tarification/service/clean/" + sousService
      : "/tarification/service/clean/:sousService",
  toTarificationGammeClean: (categorie?: string): string =>
    categorie
      ? "/tarification/service/clean/" + categorie
      : "/tarification/service/clean/:categorie",
  toCreationCategorieClean: (): string =>
    "/tarification/service/creation/categorie/clean/",
  toListeOffreBeleeClean: (): string =>
    "/tarification/service/liste/offre/clean/",
  toCreationGammeClean: (): string =>
    "/tarification/service/creation/gamme/clean/",
  toCreationListeTachesClean: (): string =>
    "/tarification/service/creation/liste-taches/clean/",
  toFraisCommissionLavageAutoADomicile: (): string =>
    "/tarification/frais-commission/lavage/auto/domicile/",
  toCreateListeTachesClean: (idCategorie?: string, idGamme?: string): string =>
    idCategorie && idGamme
      ? "/tarification/service/creation/liste-taches/clean/" +
        idCategorie +
        "/" +
        idGamme
      : "/tarification/service/creation/liste-taches/clean/:idCategorie/:idGamme",
};
