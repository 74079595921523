import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { ZoneRoutes } from "./ZoneRoutes";

const FeatureZone = {
    ZonePage: React.lazy(() => import("pages/zones/views/ZonePage")),
    ParametrePaysPage: React.lazy(
        () => import("pages/zones/views/ParametrePaysPage")
    ),
    ParametreRegionPage: React.lazy(
        () => import("pages/zones/views/ParametreRegionPage")
    ),
    ParametreRegionChoixRegionPage: React.lazy(
        () => import("pages/zones/views/ParametreRegionChoixRegionPage")
    ),
    ParametreRegionChoixPaysPage: React.lazy(
        () => import("pages/zones/views/ParametreRegionChoixPaysPage")
    ),
    ParametreBeleeCashPage: React.lazy(
        () => import("pages/zones/views/ParametreBeleeCashPage")
    ),
};

export const ZoneAppRoutes = () => (
    <Routes>
        <Route key={"routeToZonePage"} element={<RequireAuth />}>
            <Route
                path={ZoneRoutes.toZonePage()}
                element={<FeatureZone.ZonePage />}
            />
            <Route
                path={ZoneRoutes.toParametrePaysPage()}
                element={<FeatureZone.ParametrePaysPage />}
            />
            <Route
                path={ZoneRoutes.toParametreRegionPage()}
                element={<FeatureZone.ParametreRegionPage />}
            />

            <Route
                path={ZoneRoutes.toParametreRegionChoixPaysPage()}
                element={<FeatureZone.ParametreRegionChoixPaysPage />}
            />
            <Route
                path={ZoneRoutes.toParametreRegionChoixRegionPage()}
                element={<FeatureZone.ParametreRegionChoixRegionPage />}
            />
            <Route
                path={ZoneRoutes.toParametreBeleeCash()}
                element={<FeatureZone.ParametreBeleeCashPage />}
            />
        </Route>
    </Routes>
);
