import { IconProps } from "react-feather";

const WhatsAppIcon = ({ width = "18", height = "18" }: IconProps) => {
    return (
        // <svg
        //     width={width}
        //     height={height}
        //     viewBox="0 0 18 18"
        //     fill="none"
        //     xmlns="http://www.w3.org/2000/svg">
        //     <g opacity="0.75">
        //         <path
        //             d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		// c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		// c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		// c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		// c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		// c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		// c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		// c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		// c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		// C233.168,179.508,230.845,178.393,227.904,176.981z"
        //             stroke="white"
        //         />
        //         <path
        //             d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		// c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		// c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		//  M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		// l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		// c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		// C276.546,215.678,222.799,268.994,156.734,268.994z"
        //             stroke="white"
        //         />
        //     </g>
        // </svg>

    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="surface1">
        <path style={{stroke:'none', fillRule:'nonzero', fill:'rgb(100%,100%,100%)', fillOpacity:1}} d="M 14.800781 11.492188 C 14.761719 11.472656 13.300781 10.753906 13.042969 10.664062 C 12.9375 10.625 12.824219 10.585938 12.703125 10.585938 C 12.507812 10.585938 12.339844 10.683594 12.210938 10.878906 C 12.066406 11.09375 11.625 11.609375 11.488281 11.765625 C 11.472656 11.785156 11.449219 11.808594 11.433594 11.808594 C 11.417969 11.808594 11.195312 11.714844 11.125 11.6875 C 9.5625 11.007812 8.375 9.371094 8.210938 9.097656 C 8.1875 9.058594 8.1875 9.039062 8.1875 9.039062 C 8.191406 9.019531 8.246094 8.964844 8.273438 8.9375 C 8.351562 8.859375 8.4375 8.757812 8.519531 8.65625 C 8.5625 8.609375 8.601562 8.5625 8.640625 8.515625 C 8.761719 8.375 8.8125 8.265625 8.875 8.140625 L 8.910156 8.074219 C 9.0625 7.773438 8.929688 7.515625 8.890625 7.433594 C 8.855469 7.367188 8.238281 5.878906 8.171875 5.722656 C 8.015625 5.347656 7.808594 5.171875 7.519531 5.171875 C 7.492188 5.171875 7.519531 5.171875 7.40625 5.175781 C 7.269531 5.183594 6.523438 5.28125 6.195312 5.488281 C 5.84375 5.707031 5.253906 6.410156 5.253906 7.648438 C 5.253906 8.761719 5.957031 9.808594 6.261719 10.210938 C 6.269531 10.21875 6.28125 10.238281 6.304688 10.269531 C 7.464844 11.964844 8.910156 13.21875 10.378906 13.804688 C 11.789062 14.371094 12.457031 14.433594 12.835938 14.433594 C 12.996094 14.433594 13.125 14.421875 13.238281 14.410156 L 13.308594 14.40625 C 13.796875 14.363281 14.871094 13.804688 15.113281 13.128906 C 15.304688 12.59375 15.355469 12.011719 15.226562 11.800781 C 15.140625 11.65625 14.988281 11.582031 14.800781 11.492188 Z M 14.800781 11.492188 "/>
        <path style={{stroke:'none', fillRule:'nonzero', fill:'rgb(100%,100%,100%)', fillOpacity:1}} d="M 10.175781 0 C 4.761719 0 0.355469 4.375 0.355469 9.75 C 0.355469 11.488281 0.820312 13.191406 1.699219 14.679688 L 0.015625 19.65625 C -0.0195312 19.75 0.00390625 19.851562 0.0742188 19.921875 C 0.125 19.972656 0.191406 20 0.257812 20 C 0.285156 20 0.3125 19.996094 0.339844 19.988281 L 5.527344 18.339844 C 6.949219 19.097656 8.554688 19.496094 10.175781 19.496094 C 15.59375 19.5 20 15.125 20 9.75 C 20 4.375 15.59375 0 10.175781 0 Z M 10.175781 17.46875 C 8.648438 17.46875 7.167969 17.027344 5.894531 16.191406 C 5.851562 16.164062 5.804688 16.148438 5.753906 16.148438 C 5.726562 16.148438 5.699219 16.152344 5.675781 16.160156 L 3.074219 16.988281 L 3.914062 14.511719 C 3.941406 14.429688 3.929688 14.34375 3.878906 14.273438 C 2.910156 12.949219 2.398438 11.386719 2.398438 9.75 C 2.398438 5.492188 5.886719 2.03125 10.175781 2.03125 C 14.46875 2.03125 17.957031 5.492188 17.957031 9.75 C 17.957031 14.003906 14.46875 17.46875 10.175781 17.46875 Z M 10.175781 17.46875 "/>
        </g>
        </svg>
    );
};

export default WhatsAppIcon;
