import { client } from "./client";

export const getAllGroupsWhatsappAPI = async (): Promise<any | string> => {
    let err = "";

    try {
        const response = await client.get("/whatsapp/get/group/list");
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
    } catch (error: any) {
        err = error.response.data.message;
    }

    return err as string;
};


export const getAllGroupsActifWhatsappAPI = async (): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/whatsapp/get/group/actif");
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const saveGroupsActifAPI = async (data: any): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post("/whatsapp/save/group/list", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
  
export const saveMessageToSendActifAPI = async (data: any): Promise<any | string> => {
  let err = "";
  
  try {
    const response = await client.post("/whatsapp/save/message/", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
  