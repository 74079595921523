export const CompteRoutes = {
  toComptePage: (): string => "/account",
  toListeUtilisateurs: (): string => "/account/ListeUtilisateurs",
  toListeEntreprises: (): string => "/account/ListeEntreprises",
  toListeVehicules: (): string => "/account/ListeVehicules",
  toListeServices: (): string => "/account/ListeServices",
  toListeInvitations: (): string => "/account/ListeInvitations",
  toSuperviseurComptePage: (): string => "/account/superviseurs",
  toCompteDetailPage: (id?: string): string =>
    id ? "/account/" + id : "/account/:id",
  toInvitationPage: (): string => "/account/invitation",
  toEnvoyerInvitationPage: (role?: string): string =>
    role ? "/account/invitation/" + role : "/account/invitation/:role",

  toEntreprisePage: (): string => "/entreprise",
  toEntrepriseDetailPage: (slug?: string): string =>
    slug ? "/entreprise/" + slug : "/entreprise/:slug",
  toServicePage: (): string => "/service",
  toServiceDetailPage: (serviceId?: string): string =>
    serviceId ? "/service/" + serviceId : "/service/:serviceId",
};
