import { client } from "./client";

export const getListeModificationAPI = async (
  keyWord?: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get(
      "/modifications/liste?keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
