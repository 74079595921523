import { signIn } from "Api";
import { deleteCookie, getCookie } from "helpers/auth-helpers";
import { EntrepriseType, SignupDataInt, User } from "interfaces";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type AuthContext = {
  user: User | null;
  isAuthenticated: boolean;
  userEntreprise: EntrepriseType | undefined;
  isLoading: boolean;
  needUpdate: boolean;
  signupData: SignupDataInt | null;
  login: (credentials: {
    email: string;
    password: string;
  }) => Promise<any | User>;
  logout: () => void;
};

const authContext = createContext({
  user: {},
  needUpdate: false,
} as AuthContext);
const { Provider } = authContext;

export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useAuthProvider();
  return <Provider value={auth}>{props.children}</Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvider = () => {
  const [user, setUser] = useState<User | null>(null);
  // this is a flag to check if update is needed
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [signupData, setSignupData] = useState<SignupDataInt | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userEntreprise, setUserEntreprise] = useState<EntrepriseType>();

  /**
   *
   * @param param0 {email: string, password: string}
   * @returns
   */
  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const userData: User | string = await signIn(email, password);
    let loggedUser = userData as User;
    if (loggedUser?.id !== undefined) {
      setUser(loggedUser);
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
      setIsAuthenticated(true);
      setIsLoading(false);
    } else {
      let error = userData as string;
      return error;
    }

    return loggedUser;
  };

  const logout = () => {
    setIsLoading(true);
    deleteCookie("beleeBOJwt");
    localStorage.removeItem("loggedUser");
    setIsAuthenticated(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const beleeBOJwt = getCookie("beleeBOJwt");
    if (beleeBOJwt) {
      let userStringify = localStorage.getItem("loggedUser");
      if (userStringify !== null) {
        let user = JSON.parse(userStringify);
        if (user.id !== undefined) {
          setUser(user);
          setUserEntreprise(user.entreprise);
          setIsAuthenticated(true);
          setIsLoading(false);
        }
      }
    } else {
      localStorage.removeItem("loggedUser");
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  return {
    user,
    userEntreprise,
    isAuthenticated,
    isLoading,
    needUpdate,
    signupData,
    login,
    logout,
  };
};
