export const ZoneRoutes = {
    toZonePage: (): string => "/zone",
    toParametrePaysPage: (): string => "/zone/tarification",
    toParametreRegionChoixPaysPage: (): string => "/zone/pays/dispatching",
    toParametreRegionChoixRegionPage: (paysId?: any): string =>
        paysId
            ? "/zone/region/dispatching/" + paysId
            : "/zone/region/dispatching/:paysId",
    toParametreRegionPage: (): string => "/zone/parametre/dispatching",
    toParametreBeleeCash: (): string => "/zone/parametre/cash",
};
