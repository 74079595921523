const ModeleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="surface1">
        <path style={{stroke:'none', fillRule:'nonzero',fill:'rgb(100%,100%,100%)',fillOpacity:1}} d="M 14.539062 13.160156 C 14.316406 13.160156 14.097656 13.214844 13.898438 13.320312 L 12.140625 12.019531 C 12.300781 11.699219 12.382812 11.339844 12.378906 10.980469 C 12.378906 9.898438 11.648438 8.953125 10.601562 8.679688 L 10.601562 6.679688 C 11.082031 6.453125 11.390625 5.972656 11.398438 5.441406 C 11.398438 4.679688 10.78125 4.058594 10.019531 4.058594 C 9.257812 4.058594 8.640625 4.679688 8.640625 5.441406 C 8.636719 5.976562 8.949219 6.460938 9.441406 6.679688 L 9.441406 8.679688 C 8.394531 8.953125 7.664062 9.898438 7.660156 10.980469 C 7.65625 11.339844 7.738281 11.699219 7.898438 12.019531 L 6.140625 13.320312 C 5.941406 13.21875 5.722656 13.164062 5.5 13.160156 C 4.738281 13.160156 4.121094 13.777344 4.121094 14.539062 C 4.121094 15.300781 4.738281 15.921875 5.5 15.921875 C 6.261719 15.921875 6.878906 15.300781 6.878906 14.539062 C 6.878906 14.441406 6.859375 14.359375 6.859375 14.28125 L 8.699219 12.921875 C 9.511719 13.507812 10.609375 13.507812 11.421875 12.921875 L 13.261719 14.28125 C 13.242188 14.367188 13.238281 14.453125 13.238281 14.539062 C 13.238281 15.300781 13.859375 15.921875 14.621094 15.921875 C 15.382812 15.921875 16 15.300781 16 14.539062 C 15.945312 13.769531 15.3125 13.167969 14.539062 13.160156 Z M 10.28125 12.140625 C 10.1875 12.164062 10.09375 12.175781 10 12.179688 C 9.46875 12.167969 9.007812 11.808594 8.859375 11.300781 C 8.835938 11.203125 8.824219 11.101562 8.820312 11 C 8.820312 10.574219 9.050781 10.183594 9.421875 9.980469 C 9.792969 9.765625 10.25 9.765625 10.621094 9.980469 C 10.992188 10.183594 11.222656 10.574219 11.21875 11 C 11.214844 11.101562 11.203125 11.203125 11.179688 11.300781 C 11.039062 11.710938 10.703125 12.027344 10.28125 12.140625 Z M 10.28125 12.140625 "/>
        </g>
        </svg>
    );
}

export default ModeleIcon;