import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { CleanRoutes } from "./CleanRoutes";

const FeatureClean = {
  HomePage: React.lazy(() => import("pages/clean/views/HomePage")),
  TypeVehiculeMobilierPage: React.lazy(
    () => import("pages/clean/views/TypeVehiculeMobilierPage")
  ),
  TypeMobilierDetailPage: React.lazy(
    () => import("pages/clean/views/TypeMobilierDetailPage")
  ),
};

export const CleanAppRoutes = () => (
  <Routes>
    <Route key={"routeToCleanPage"} element={<RequireAuth />}>
      <Route
        path={CleanRoutes.toHomeCleanPage()}
        element={<FeatureClean.HomePage />}
      />
      <Route
        path={CleanRoutes.toTypeVehiculeMobilierPage()}
        element={<FeatureClean.TypeVehiculeMobilierPage />}
      />
      <Route
        path={CleanRoutes.toTypeMobilierDetails()}
        element={<FeatureClean.TypeMobilierDetailPage />}
      />
    </Route>
  </Routes>
);
