import { EntrepriseType } from "interfaces";
import { client } from "./client";

export const getEntreprisesAPI = async (
  filter?: string,
  keyWord?: string
): Promise<EntrepriseType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprises?filter=" + filter + "&keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as EntrepriseType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getCountAllEntrepriseByEtatAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/entreprise/count/all/belee");
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getEntrepriseDetails = async (
  slug: string
): Promise<EntrepriseType | string> => {
  let err = "";
  try {
    const response = await client.get("/entreprises/" + slug);
    if (response.status === 200) {
      return response.data as EntrepriseType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const validerEntrepriseAPI = async (slug: string) => {
  let err = "";
  try {
    const response = await client.post("/entreprises/validate/" + slug);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setEtatEntrepriseAPI = async (
  entrepriseSlug: string,
  etat: string,
  message?: string
) => {
  let err = "";
  let data = {
    entrepriseSlug,
    etat,
    message,
  };
  try {
    const response = await client.post("/entreprises/set-etat", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const handleValiderInvitationAPI = async (slug: string, data: any) => {
  let err = "";
  try {
    const response = await client.post(
      "/entreprises/activer/invitation/" + slug,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setOptionSiteEntrepriseAPI = async (
  slug: string,
  typeSite: any
) => {
  let err = "";
  try {
    const response = await client.post(
      "/entreprises/activer/site/" + slug,
      typeSite
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
