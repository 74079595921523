export const ModeleCleanRoutes = {
  toMenuModele: (): string => "/modele-clean/",
  toCreateDepartement: (): string => "/modele-clean/departement/create",

  toListeDepartementModeleClean: (service?: string): string =>
    service
      ? "/modele-clean/lavage-auto-domicile/departement/liste/" + service
      : "/modele-clean/lavage-auto-domicile/departement/liste/:service",
  toListeSousDepartementModeleClean: (service?: string): string =>
    service
      ? "/modele-clean/domicile/sous-departement/liste/" + service
      : "/modele-clean/domicile/sous-departement/liste/:service",
  toModifierDepartement: (idDepartementContenu?: string): string =>
    idDepartementContenu
      ? "/modele-clean/departement/modifier/" + idDepartementContenu
      : "/modele-clean/departement/modifier/:idDepartementContenu",

  toCreateContenuDepartementModeleClean: (service?: string): string =>
    service
      ? "/modele-clean/create/contenu/" + service
      : "/modele-clean/create/contenu/:service",
  toCreateContenuSousDepartementModeleClean: (service?: string): string =>
    service
      ? "/modele-clean/sous-departement/create/contenu/" + service
      : "/modele-clean/sous-departement/create/contenu/:service",
  toDetailContenuDepartementModeleClean: (
    idDepartementContenu?: string
  ): string =>
    idDepartementContenu
      ? "/modele-clean/domicile/departement/contenu/" + idDepartementContenu
      : "/modele-clean/domicile/departement/contenu/:idDepartementContenu",
  toDetailContenuSousDepartementModeleClean: (
    idSousDepartementContenu?: string
  ): string =>
    idSousDepartementContenu
      ? "/modele-clean/domicile/sous-departement/contenu/" +
        idSousDepartementContenu
      : "/modele-clean/domicile/sous-departement/contenu/:idSousDepartementContenu",
};
