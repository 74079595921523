import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { ModificationRoutes } from "./ModificationRoutes";
import React from "react";

const FeatureModification = {
  ListeModificationPage: React.lazy(
    () => import("pages/modifications/views/ListeModificationPage")
  ),
};

export const ModificationAppRoutes = () => (
  <Routes>
    <Route key={"routeToModificationPage"} element={<RequireAuth />}>
      <Route
        path={ModificationRoutes.toListeModificationPage()}
        element={<FeatureModification.ListeModificationPage />}
      />
    </Route>
  </Routes>
);
