import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { CompteRoutes } from "./CompteRoutes";

const FeatureCompte = {
  ComptePage: React.lazy(() => import("pages/compte/views/ComptePage")),
  ListeUtilisateurs: React.lazy(() => import("pages/compte/views/ListeUtilisateurs")),
  ListeEntreprises: React.lazy(() => import("pages/compte/views/ListeEntreprises")),
  ListeVehicules: React.lazy(() => import("pages/compte/views/ListeVehicules")),
  ListeServices: React.lazy(() => import("pages/compte/views/ListeServices")),
  ListeInvitations: React.lazy(() => import("pages/compte/views/ListeInvitations")),
  CompteDetailPage: React.lazy(
    () => import("pages/compte/views/CompteDetailPage")
  ),
  InvitationPage: React.lazy(() => import("pages/compte/views/InvitationPage")),
  EnvoyerInvitationPage: React.lazy(
    () => import("pages/compte/views/EnvoyerInvitationPage")
  ),
  EntreprisePage: React.lazy(() => import("pages/compte/views/EntreprisePage")),
  EntrepriseDetailPage: React.lazy(
    () => import("pages/compte/views/EntrepriseDetailPage")
  ),
  ServicePage: React.lazy(() => import("pages/compte/views/ServicePage")),
  ServiceDetailPage: React.lazy(
    () => import("pages/compte/views/ServiceDetailPage")
  ),
  SuperviseurComptePage: React.lazy(
    () => import("pages/compte/views/SuperviseurComptePage")
  ),
};

export const CompteAppRoutes = () => (
  <Routes>
    <Route key={"routeToComptePage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toComptePage()}
        element={<FeatureCompte.ComptePage />}
      />
    </Route>
    <Route key={"routeToListeUtilisateurs"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toListeUtilisateurs()}
        element={<FeatureCompte.ListeUtilisateurs />}
      />
    </Route>
    <Route key={"routeToListeVehicules"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toListeVehicules()}
        element={<FeatureCompte.ListeVehicules />}
      />
    </Route>
    <Route key={"routeToListeEntreprises"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toListeEntreprises()}
        element={<FeatureCompte.ListeEntreprises />}
      />
    </Route>
    <Route key={"routeToListeServices"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toListeServices()}
        element={<FeatureCompte.ListeServices />}
      />
    </Route>
    <Route key={"routeToListeInvitations"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toListeInvitations()}
        element={<FeatureCompte.ListeInvitations/>}
      />
    </Route>
    <Route key={"routeToSuperviseurComptePage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toSuperviseurComptePage()}
        element={<FeatureCompte.SuperviseurComptePage />}
      />
    </Route>
    <Route key={"routeToCompteDetailPage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toCompteDetailPage()}
        element={<FeatureCompte.CompteDetailPage />}
      />
    </Route>

    <Route key={"routeToInvitationPage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toInvitationPage()}
        element={<FeatureCompte.InvitationPage />}
      />
    </Route>
    <Route key={"routeToEnvoyerInvitation"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toEnvoyerInvitationPage()}
        element={<FeatureCompte.EnvoyerInvitationPage />}
      />
    </Route>
    <Route key={"routeToEntreprise"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toEntreprisePage()}
        element={<FeatureCompte.EntreprisePage />}
      />
    </Route>
    <Route key={"routeTotoEntrepriseDetailPage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toEntrepriseDetailPage()}
        element={<FeatureCompte.EntrepriseDetailPage />}
      />
    </Route>
    <Route key={"routeToServicePage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toServicePage()}
        element={<FeatureCompte.ServicePage />}
      />
    </Route>
    <Route key={"routeToServiceDetailPage"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toServiceDetailPage()}
        element={<FeatureCompte.ServiceDetailPage />}
      />
    </Route>
  </Routes>
);
