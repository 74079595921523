import Loader from "components/Elements/loaders/Loader";
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { BeleeApplicationRoutes } from "./BeleeApplicationRoutes";

export const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <BeleeApplicationRoutes />
      </Suspense>
    </Router>
  );
};
