import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { VehiculeRoutes } from "./VehiculeRoutes";

const FeatureVehicule = {
  VehiculePage: React.lazy(() => import("pages/vehicule/views/VehiculePage")),
  VehiculeDetailPage: React.lazy(
    () => import("pages/vehicule/views/VehiculeDetailPage")
  ),
  TypeVehiculeDetailPage: React.lazy(
    () => import("pages/vehicule/views/TypeVehiculeDetailPage")
  ),
};

export const VehiculeAppRoutes = () => (
  <Routes>
    <Route key={"routeToVehicule"} element={<RequireAuth />}>
      <Route
        path={VehiculeRoutes.toVehicule()}
        element={<FeatureVehicule.VehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toVehiculeDetails()}
        element={<FeatureVehicule.VehiculeDetailPage />}
      />
      <Route
        path={VehiculeRoutes.toTypeVehiculeDetails()}
        element={<FeatureVehicule.TypeVehiculeDetailPage />}
      />
    </Route>
  </Routes>
);
