import { ConversionData } from "./ApiInterfaces";
import { client } from "./client";

export const getParametreAPI = async (paysName: string): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/zones/parametre/get/tva/" + paysName);
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllDeviseConversionAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/zones/devise-conversion/liste");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createDeviseConversionAPI = async (
  envoi: string,
  reception: string,
  valeur: string
): Promise<ConversionData[] | undefined> => {
  let err = "";
  let data = {
    envoi,
    reception,
    valeur,
  };
  try {
    const response = await client.post("/zones/devise-conversion", data);
    if (response.status === 200) {
      return response.data as ConversionData[];
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
};
