import { AdressePublicType, PaysType, RegionType, VilleType } from "interfaces";
import {
  LieuPublicData,
  PaysData,
  RegionData,
  VilleData,
} from "./ApiInterfaces";
import { client, clientFile } from "./client";

export const getPaysAPI = async (): Promise<PaysType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/pays");
    if (response.status === 200) {
      return response.data as PaysType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const creerPaysAPI = async (data: PaysData) => {
  let err = "";

  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post("/zones/pays/create", formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const updatePaysAPI = async (id_pays: string, data: PaysData) => {
  let err = "";

  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/zones/pays/update/" + id_pays,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getRegionsAPI = async (
  id_pays: string
): Promise<RegionType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/region/" + id_pays);
    if (response.status === 200) {
      return response.data as RegionType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getOneRegionAPI = async (
  id_pays: string
): Promise<RegionType | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/region/get/" + id_pays);
    if (response.status === 200) {
      return response.data as RegionType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const creerRegionAPI = async (data: RegionData) => {
  let err = "";

  try {
    const response = await client.post("/zones/region/create", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const updateRegionAPI = async (id: any, data: RegionData) => {
  let err = "";

  try {
    const response = await client.post("zones/region/update/" + id, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const updateVilleAPI = async (id: any, data: VilleData) => {
  let err = "";

  try {
    const response = await client.post("/zones/ville/update/" + id, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const creerVilleAPI = async (data: VilleData) => {
  let err = "";

  try {
    const response = await client.post("/zones/ville/create", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

// departement vtc
export const creerDepartementsAPI = async (data: any) => {
  let err = "";
  try {
    const response = await client.post("/zones/departement/create", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

// departement pour clean
export const creerDepartementCleanAPI = async (data: any) => {
  let err = "";
  try {
    const response = await client.post("/zones/departement/clean/create", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsCleanAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/zones/departement/get/clean");
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsCleanWhoDontHaveContenuAPI = async (
  service: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departement/get/clean/" + service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get("/zones/departements/" + service);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllDepartementsContenuAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/sous-departements/get/all/departementcontenu/" +
        service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllDepartementsCleanContenuAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/sous-departements/get/all/clean/departementcontenu/" +
        service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsContenuListeAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/contenu/liste/" + service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsCleanContenuListeAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/clean/contenu/liste/" + service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getSousDepartementsContenuDetailAPI = async (
  idSousDepartement: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/contenu/" + idSousDepartement
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getSousDepartementsCleanContenuDetailAPI = async (
  idSousDepartement: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/clean/contenu/" + idSousDepartement
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllSousDepartementsContenuListeAPI = async (
  service: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/sous-departements/get/all/sousdepartementcontenu/" +
        service
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllSousDepartementsCleanContenuListeAPI = async (
  service: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/sous-departements/get/all/clean/sousdepartementcontenu/" +
        service
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsContenuDetailAPI = async (
  idDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/contenu/" + idDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsCleanContenuDetailAPI = async (
  idDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/clean/contenu/" + idDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteDepartementsContenuDetailAPI = async (
  idDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/contenu/delete/" + idDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteDepartementsCleanContenuDetailAPI = async (
  idDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/clean/contenu/delete/" + idDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteSousDepartementsContenuDetailAPI = async (
  idSousDepartement: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/contenu/delete/" + idSousDepartement
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const deleteSousDepartementsCleanContenuDetailAPI = async (
  idSousDepartement: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/clean/contenu/delete/" + idSousDepartement
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getVillesAPI = async (
  id_region: string
): Promise<VilleType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/ville/" + id_region);
    if (response.status === 200) {
      return response.data as VilleType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getLieuPublicAPI = async (
  id_ville: string
): Promise<AdressePublicType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/lieupublic/" + id_ville);
    if (response.status === 200) {
      return response.data as AdressePublicType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const creerLieuPublicAPI = async (data: LieuPublicData) => {
  let err = "";

  try {
    const response = await client.post("/zones/lieupublic/create", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const modifierLieuPublicAPI = async (
  lieuId: string,
  data: LieuPublicData
) => {
  let err = "";

  try {
    const response = await client.post(
      "/zones/lieupublic/update/" + lieuId,
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const supprimerLieuPublicAPI = async (lieuId: string) => {
  let err = "";

  try {
    const response = await client.post("/zones/lieupublic/delete/" + lieuId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getLieuxByCountry = async (
  id_pays: string
): Promise<AdressePublicType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/pays/lieupublic/" + id_pays);
    if (response.status === 200) {
      return response.data as AdressePublicType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createContenuDepartementAPI = async (data: any) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/zones/departements/create/contenu",
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const createContenuDepartementCleanAPI = async (data: any) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/zones/departements/create/contenu/clean",
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const createContenuSousDepartementAPI = async (data: any) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/zones/sous-departements/create/contenu/" + data.slug,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const createContenuSousDepartementCleanAPI = async (data: any) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/zones/sous-departements/create/contenu/clean/" + data.slug,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
