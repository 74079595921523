import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { ModeleRoutes } from "./ModeleRoutes";

const FeatureDepartement = {
  MenuModelePage: React.lazy(
    () => import("pages/modeleTemplate/views/SousMenuModelePage")
  ),
  CreateDepartementPage: React.lazy(
    () => import("pages/modeleTemplate/views/CreateDepartementPage")
  ),


  ListeDepartementPageModeleTaxi: React.lazy(
    () => import("pages/modeleTemplate/views/modeleTaxi/ListeDepartementPage")
  ),
  ListeDepartementPageModeleVtc: React.lazy(
    () => import("pages/modeleTemplate/views/modeleVtc/ListeDepartementPage")
  ),





  ListeSousDepartementPageModeleTaxi: React.lazy(
    () => import("pages/modeleTemplate/views/modeleTaxi/ListeSousDepartementPage")
  ),
  
  ListeSousDepartementPageModeleVtc: React.lazy(
    () => import("pages/modeleTemplate/views/modeleVtc/ListeSousDepartementPage")
  ),






  ContenuDepartementPageModeleTaxi: React.lazy(
    () => import("pages/modeleTemplate/views/modeleTaxi/ContenuDepartementPage")
  ),
  ContenuDepartementPageModeleVtc: React.lazy(
    () => import("pages/modeleTemplate/views/modeleVtc/ContenuDepartementPage")
  ),




  ContenuSousDepartementPageModeleTaxi: React.lazy(
    () => import("pages/modeleTemplate/views/modeleTaxi/ContenuSousDepartementPage")
  ),
  ContenuSousDepartementPageModeleVtc: React.lazy(
    () => import("pages/modeleTemplate/views/modeleVtc/ContenuSousDepartementPage")
  ),





  CreateContenuDepartementPageModeleTaxi: React.lazy(
    () => import("pages/modeleTemplate/views/modeleTaxi/CreateContenuDepartementPage")
  ),
  CreateContenuDepartementPageModeleVtc: React.lazy(
    () => import("pages/modeleTemplate/views/modeleVtc/CreateContenuDepartementPage")
  ),





  CreateContenuSousDepartementPageModeleTaxi: React.lazy(
    () => import("pages/modeleTemplate/views/modeleTaxi/CreateContenuSousDepartementPage")
  ),
  CreateContenuSousDepartementPageModeleVtc: React.lazy(
    () => import("pages/modeleTemplate/views/modeleVtc/CreateContenuSousDepartementPage")
  ),
};

export const ModeleAppRoutes = () => (
  <Routes>
    <Route key={"routeToModele"} element={<RequireAuth />}>
      <Route
        path={ModeleRoutes.toCreateDepartement()}
        element={<FeatureDepartement.CreateDepartementPage />}
      />

      <Route
        path={ModeleRoutes.toCreateContenuDepartementModeleTaxi()}
        element={<FeatureDepartement.CreateContenuDepartementPageModeleTaxi />}
      />
      <Route
        path={ModeleRoutes.toCreateContenuDepartementModeleVtc()}
        element={<FeatureDepartement.CreateContenuDepartementPageModeleVtc />}
      />

      <Route
        path={ModeleRoutes.toMenuModele()}
        element={<FeatureDepartement.MenuModelePage />}
      />

      <Route
        path={ModeleRoutes.toListeDepartementModeleTaxi()}
        element={<FeatureDepartement.ListeDepartementPageModeleTaxi />}
      />
      <Route
        path={ModeleRoutes.toListeDepartementModeleVtc()}
        element={<FeatureDepartement.ListeDepartementPageModeleVtc />}
      />

      <Route
        path={ModeleRoutes.toDetailContenuDepartementModeleTaxi()}
        element={<FeatureDepartement.ContenuDepartementPageModeleTaxi />}
      />
      <Route
        path={ModeleRoutes.toDetailContenuDepartementModeleVtc()}
        element={<FeatureDepartement.ContenuDepartementPageModeleVtc />}
      />

      <Route
        path={ModeleRoutes.toDetailContenuSousDepartementModeleTaxi()}
        element={<FeatureDepartement.ContenuSousDepartementPageModeleTaxi />}
      />

      <Route
        path={ModeleRoutes.toDetailContenuSousDepartementModeleVtc()}
        element={<FeatureDepartement.ContenuSousDepartementPageModeleVtc />}
      />

      <Route
        path={ModeleRoutes.toListeSousDepartementModeleTaxi()}
        element={<FeatureDepartement.ListeSousDepartementPageModeleTaxi />}
      />
      <Route
        path={ModeleRoutes.toListeSousDepartementModeleVtc()}
        element={<FeatureDepartement.ListeSousDepartementPageModeleVtc />}
      />

      <Route
        path={ModeleRoutes.toCreateContenuSousDepartementModeleTaxi()}
        element={
          <FeatureDepartement.CreateContenuSousDepartementPageModeleTaxi />
        }
      />
      <Route
        path={ModeleRoutes.toCreateContenuSousDepartementModeleVtc()}
        element={
          <FeatureDepartement.CreateContenuSousDepartementPageModeleVtc />
        }
      />
    </Route>
  </Routes>
);
