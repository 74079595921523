import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { ModeleCleanRoutes } from "./ModeleCleanRoutes";

const FeatureDepartement = {
  MenuModelePage: React.lazy(
    () => import("pages/modeleTemplateClean/views/SousMenuModelePage")
  ),
  CreateDepartementPage: React.lazy(
    () => import("pages/modeleTemplateClean/views/CreateDepartementPage")
  ),

  ListeDepartementPageModeleDomicile: React.lazy(
    () =>
      import(
        "pages/modeleTemplateClean/views/modeleDomicile/ListeDepartementPage"
      )
  ),

  ListeSousDepartementPageModeleDomicile: React.lazy(
    () =>
      import(
        "pages/modeleTemplateClean/views/modeleDomicile/ListeSousDepartementPage"
      )
  ),

  ContenuDepartementPageModeleDomicile: React.lazy(
    () =>
      import(
        "pages/modeleTemplateClean/views/modeleDomicile/ContenuDepartementPage"
      )
  ),

  ContenuSousDepartementPageModeleDomicile: React.lazy(
    () =>
      import(
        "pages/modeleTemplateClean/views/modeleDomicile/ContenuSousDepartementPage"
      )
  ),

  CreateContenuDepartementPageModeleDomicile: React.lazy(
    () =>
      import(
        "pages/modeleTemplateClean/views/modeleDomicile/CreateContenuDepartementPage"
      )
  ),

  CreateContenuSousDepartementPageModeleDomicile: React.lazy(
    () =>
      import(
        "pages/modeleTemplateClean/views/modeleDomicile/CreateContenuSousDepartementPage"
      )
  ),
};

export const ModeleCleanAppRoutes = () => (
  <Routes>
    <Route key={"routeToModele"} element={<RequireAuth />}>
      <Route
        path={ModeleCleanRoutes.toCreateDepartement()}
        element={<FeatureDepartement.CreateDepartementPage />}
      />

      <Route
        path={ModeleCleanRoutes.toMenuModele()}
        element={<FeatureDepartement.MenuModelePage />}
      />

      <Route
        path={ModeleCleanRoutes.toCreateContenuDepartementModeleClean()}
        element={
          <FeatureDepartement.CreateContenuDepartementPageModeleDomicile />
        }
      />

      <Route
        path={ModeleCleanRoutes.toListeDepartementModeleClean()}
        element={<FeatureDepartement.ListeDepartementPageModeleDomicile />}
      />

      <Route
        path={ModeleCleanRoutes.toDetailContenuDepartementModeleClean()}
        element={<FeatureDepartement.ContenuDepartementPageModeleDomicile />}
      />

      <Route
        path={ModeleCleanRoutes.toDetailContenuSousDepartementModeleClean()}
        element={
          <FeatureDepartement.ContenuSousDepartementPageModeleDomicile />
        }
      />

      <Route
        path={ModeleCleanRoutes.toListeSousDepartementModeleClean()}
        element={<FeatureDepartement.ListeSousDepartementPageModeleDomicile />}
      />

      <Route
        path={ModeleCleanRoutes.toCreateContenuSousDepartementModeleClean()}
        element={
          <FeatureDepartement.CreateContenuSousDepartementPageModeleDomicile />
        }
      />
    </Route>
  </Routes>
);
