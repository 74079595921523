import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { TarifcationRoutes } from "./TarifcationRoutes";

const FeatureTarfication = {
  ForfaitPage: React.lazy(() => import("pages/tarification/views/ForfaitPage")),
  TarificationServiceClean: React.lazy(
    () => import("pages/tarification/views/TarificationServiceBeleeCleanPage")
  ),
  TarificationGammeServiceClean: React.lazy(
    () =>
      import("pages/tarification/views/TarificationGammeServiceBeleeCleanPage")
  ),
  TarificationCategorieServiceClean: React.lazy(
    () => import("pages/tarification/views/CreationCategorieBeleeCleanPage")
  ),
  TarificationCreationGammeClean: React.lazy(
    () => import("pages/tarification/views/CreationGammeBeleeCleanPage")
  ),
  TarificationCreationListeTachesClean: React.lazy(
    () => import("pages/tarification/views/CreationListeTachesBeleeCleanPage")
  ),
  CreateListeTachesClean: React.lazy(
    () => import("pages/tarification/views/CreateListeTachesBeleeCleanPage")
  ),
  CreateListeOffreClean: React.lazy(
    () => import("pages/tarification/views/CreationListeOffreBeleeCleanPage")
  ),
  ForfaitDetailPage: React.lazy(
    () => import("pages/tarification/views/ForfaitDetailPage")
  ),
  FraisCommissionClean: React.lazy(
    () =>
      import(
        "pages/tarification/views/FraisEtCommissionLavageAutoADomicilePage"
      )
  ),
};

export const TarifcationAppRoutes = () => (
  <Routes>
    <Route key={"routeToZonePage"} element={<RequireAuth />}>
      <Route
        path={TarifcationRoutes.toForfait()}
        element={<FeatureTarfication.ForfaitPage />}
      />
      <Route
        path={TarifcationRoutes.toForfaitDetail()}
        element={<FeatureTarfication.ForfaitDetailPage />}
      />
      <Route
        path={TarifcationRoutes.toTarificationClean()}
        element={<FeatureTarfication.TarificationServiceClean />}
      />
      <Route
        path={TarifcationRoutes.toTarificationGammeClean()}
        element={<FeatureTarfication.TarificationGammeServiceClean />}
      />

      <Route
        path={TarifcationRoutes.toCreationCategorieClean()}
        element={<FeatureTarfication.TarificationCategorieServiceClean />}
      />
      <Route
        path={TarifcationRoutes.toCreationGammeClean()}
        element={<FeatureTarfication.TarificationCreationGammeClean />}
      />
      <Route
        path={TarifcationRoutes.toCreationListeTachesClean()}
        element={<FeatureTarfication.TarificationCreationListeTachesClean />}
      />
      <Route
        path={TarifcationRoutes.toCreateListeTachesClean()}
        element={<FeatureTarfication.CreateListeTachesClean />}
      />
      <Route
        path={TarifcationRoutes.toFraisCommissionLavageAutoADomicile()}
        element={<FeatureTarfication.FraisCommissionClean />}
      />
      <Route
        path={TarifcationRoutes.toListeOffreBeleeClean()}
        element={<FeatureTarfication.CreateListeOffreClean />}
      />
    </Route>
  </Routes>
);
