import { ServiceType } from "interfaces";
import { client } from "./client";

export const getServicesAPI = async (
  filter?: string,
  keyWord?: string
): Promise<ServiceType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/services?filter=" + filter + "&keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as ServiceType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getServiceBeleeCashAPI = async (
  filter?: string,
  keyWord?: string
): Promise<ServiceType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/services/cash?filter=" + filter + "&keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as ServiceType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getCountAllServiceByEtatAPI = async (
  typeservice?: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get(
      "/service/count/all/belee/" + typeservice
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getServiceDetails = async (
  serviceId: string
): Promise<ServiceType | string> => {
  let err = "";
  try {
    const response = await client.get("/service/" + serviceId);
    if (response.status === 200) {
      return response.data as ServiceType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const validerServiceAPI = async (serviceId: string) => {
  let err = "";
  try {
    const response = await client.post("/service/validate/" + serviceId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setEtatServiceAPI = async (serviceId: string, etat: string) => {
  let err = "";
  let data = {
    serviceId,
    etat,
  };
  try {
    const response = await client.post("service/set-etat", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
