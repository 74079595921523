import React from "react";

import { Route, Routes } from "react-router-dom";
import { NotRequireAuth } from "utils";
import { AuthRoutes } from "./AuthRoutes";

const FeatureAuth = {
  LoginPage: React.lazy(() => import("pages/auth/views/Login")),
  RegisterPage: React.lazy(() => import("pages/auth/views/Register")),
  VerificationPage: React.lazy(() => import("pages/auth/views/Verification")),
  SuccessPage: React.lazy(() => import("pages/auth/views/Success")),
  ResetPasswordPage: React.lazy(() => import("pages/auth/views/ResetPassword")),
  ResetPasswordConfirmPage: React.lazy(
    () => import("pages/auth/views/ResetPassConfirm")
  ),
  NewPasswordPage: React.lazy(() => import("pages/auth/views/NewPassword")),
  NewPasswordSuccessPage: React.lazy(
    () => import("pages/auth/views/NewPassSuccess")
  ),
  ConfirmAccountPage: React.lazy(
    () => import("pages/auth/views/ConfirmAccount")
  ),
};

export const AuthAppRoutes = () => (
  <Routes>
    <Route key="routeLoginPage" element={<NotRequireAuth />}>
      <Route path={AuthRoutes.toLogin()} element={<FeatureAuth.LoginPage />} />
    </Route>
    <Route key="routeRegisterPage" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toRegister()}
        element={<FeatureAuth.RegisterPage />}
      />
    </Route>
    <Route key="routeVerifyPage" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toVerify()}
        element={<FeatureAuth.VerificationPage />}
      />
    </Route>
    <Route key="routeSuccessPage" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toSuccess()}
        element={<FeatureAuth.SuccessPage />}
      />
    </Route>
    <Route key="routeResetPassword" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toResetPassword()}
        element={<FeatureAuth.ResetPasswordPage />}
      />
    </Route>
    <Route key="routeResetPasswordConfirm" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toResetPasswordConfirm()}
        element={<FeatureAuth.ResetPasswordConfirmPage />}
      />
    </Route>
    <Route key="routeNewPass" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toNewPassword()}
        element={<FeatureAuth.NewPasswordPage />}
      />
    </Route>
    <Route key="routeNewPassSuccess" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toNewPassSuccess()}
        element={<FeatureAuth.NewPasswordSuccessPage />}
      />
    </Route>
    {/* <Route key="routeNewPassSuccdddess" element={<NotRequireAuth />}>
      <Route
        path={AuthRoutes.toConfirmAccount()}
        element={<FeatureAuth.ConfirmAccountPage />}
      />
    </Route> */}
  </Routes>
);
