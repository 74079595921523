export const BanqueRoutes = {
  toBanque: (): string => "/banque",
  toBanqueRechargerChoixUser: (): string => "/banque/recharger/choix",
  toBanqueRechargerUser: (userId?: string, type?: string): string =>
    userId && type
      ? "/banque/recharger/" + userId + "/" + type
      : "/banque/recharger/:userId/:type",
  toBanqueTransactionDetail: (transactionId?: string): string =>
    transactionId
      ? "/banque/transaction/" + transactionId
      : "/banque/transaction/:transactionId",

  toTransactionListPage: (userId?: string): string =>
    userId
      ? "/banque/transaction/liste/" + userId
      : "/banque/transaction/liste/:userId",

  toTransactionBeleeListPage: (): string => "/banque/transaction/belee/liste",
};
