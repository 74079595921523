import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "utils";
import { BanqueRoutes } from "./BanqueRoutes";

const FeatureBanque = {
  BanquePage: React.lazy(() => import("pages/banques/views/BanquePage")),
  TransactionDetailPage: React.lazy(
    () => import("pages/banques/views/BanqueDetailPage")
  ),
  TransactionListePage: React.lazy(
    () => import("pages/banques/views/TransactionListPage")
  ),
  TransactionBeleeListePage: React.lazy(
    () => import("pages/banques/views/TransactionBeleeListPage")
  ),
  BanqueRechargerChoixUser: React.lazy(
    () => import("pages/banques/views/ChoixRechargerListUserPage")
  ),
  BanqueRechargerUser: React.lazy(
    () => import("pages/banques/views/RechargerUserPage")
  ),
};
export const BanqueAppRoutes = () => (
  <Routes>
    <Route key={"routeBanque"} element={<RequireAuth />}>
      <Route
        path={BanqueRoutes.toBanque()}
        element={<FeatureBanque.BanquePage />}
      />
      <Route
        path={BanqueRoutes.toBanqueTransactionDetail()}
        element={<FeatureBanque.TransactionDetailPage />}
      />
      <Route
        path={BanqueRoutes.toTransactionListPage()}
        element={<FeatureBanque.TransactionListePage />}
      />
      <Route
        path={BanqueRoutes.toTransactionBeleeListPage()}
        element={<FeatureBanque.TransactionBeleeListePage />}
      />
      <Route
        path={BanqueRoutes.toBanqueRechargerChoixUser()}
        element={<FeatureBanque.BanqueRechargerChoixUser />}
      />
      <Route
        path={BanqueRoutes.toBanqueRechargerUser()}
        element={<FeatureBanque.BanqueRechargerUser />}
      />
    </Route>
  </Routes>
);
