import { IconType } from "interfaces";

const ListIcon = ({ width = "19", height = "21" }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.75">
        <path
          d="M1 7.33203H17.8889"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8333 3.11133H2.05556C1.47259 3.11133 1 3.58392 1 4.16688V18.9447C1 19.5276 1.47259 20.0002 2.05556 20.0002H16.8333C17.4163 20.0002 17.8889 19.5276 17.8889 18.9447V4.16688C17.8889 3.58392 17.4163 3.11133 16.8333 3.11133Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6641 1V3.11111"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.21875 1V3.11111"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ListIcon;
