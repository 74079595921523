import { client } from "./client";

export const getAllBeleeTransactionAPI = async (
  filter?: string,
  startDate?: string,
  endDate?: string
): Promise<any> => {
  let err = "";
  try {
    const params = new URLSearchParams();
    if (filter) params.append("filter", filter);
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    const response = await client.get(
      "/transaction/history/belee?" + params.toString()
    );
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllMontantremboursableAPI = async (
  filter?: string,
  keyWord?: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/banque/get/montant-remboursable");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllBeleeTransactionFluxSortantAPI = async (
  filter?: string,
  startDate?: string,
  endDate?: string
): Promise<any> => {
  let err = "";
  try {
    const params = new URLSearchParams();
    if (filter) params.append("filter", filter);
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    const response = await client.get(
      "/transaction/flux/all/sortant?" + params.toString()
    );
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response?.data?.message || error.message;
  }
  return err;
};

export const getAllUserBalanceAPI = async (currency: string): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/banque/all/users/solde/" + currency);
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAccountAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/account");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getNombreUserEnligneAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/auth/user/connect/get/");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getTransactionDetailAPI = async (
  transactionId: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/transaction/" + transactionId);
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setStatusTransactionAPI = async (
  transactionId: string,
  data: any
) => {
  let err = "";

  try {
    const response = await client.post(
      "/wallet/transaction/statut/" + transactionId,
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const validerRechargementVirementAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/wallet/virement/addfund", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setStatusRetraitAPI = async (transactionId: string, data: any) => {
  let err = "";

  try {
    const response = await client.post(
      "/wallet/retrait/statut/" + transactionId,
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllBeleeHistoryAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/belee/history/");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllHistoryAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/history");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllUserTransactionAPI = async (userId: any): Promise<any> => {
  

  let err = "";
  try {
    const response = await client.get("/wallet/user/history/" + userId);
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const addFundEspeceAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/wallet/espece/addfund", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
